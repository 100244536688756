@import './contacts/contacts.css';

.about {
  position: relative;

  @media (--forTabletPortraitUp) {
    margin: 12px 16px;
  }

  @media (--forPhoneOnly) {
    margin: 8px;
    margin-top: 16px;
  }
  max-width: 800px;
}

.about > p {
  display: inline;
  margin: 0;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: -0.1px;

  @media (--forPhoneOnly) {
    font-size: 7.8vw;
    line-height: 103%;
  }

  @media (--forTabletPortraitUp) {
    font-size: 34px;
    line-height: 106%;
  }
}
